import axios from 'axios';

// verify Token
export const verifyToken = async (user) => {
    return (
        await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/verify',
            data: {
              jwtToken: user.token,
              },
            headers: {
                'Content-Type': 'application/json',
              },
          })
        // .get("http://10.0.2.2:4000/api/v1/register")
        .then(response => response)
    );
  };