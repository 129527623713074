import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AddForm from './components/AddForm';
import EditForm from './components/EditForm';
import ModuleList from './components/package_module/ModuleList';
import AddModule from './components/package_module/AddModule';
import SoftwareList from './components/software/SoftwareList';
import AddSoftware from './components/software/AddSoftware';
import UpdateVersion from './components/software/UpdateVersion';
import AdvertiseList from './components/advertisement/AdvertisementList';
import AddAdvertise from './components/advertisement/AddAdvertisement';
import RequireAuth from './components/RequireAuth';
import ChromeUpdateInfoList from './components/chromeUpdateInfo/ChromeUpdateInfoList';
import AddChromeUpdateInfo from './components/chromeUpdateInfo/AddChromeUpdateInfo';

import { PrimeReactProvider } from 'primereact/api';


const App = () => {  
  return (
    <PrimeReactProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Dashboard/>} />
            <Route path="/licence/add" element={<AddForm/>} />
            <Route path="/licence/edit" element={<EditForm/>} />
            <Route path="/module" element={<ModuleList/>} />
            <Route path="/module/add" element={<AddModule/>} />
            <Route path="/software" element={<SoftwareList/>} />
            <Route path="/software/add" element={<AddSoftware/>} />
            <Route path="/software/update" element={<UpdateVersion/>} />
            <Route path="/advertisement" element={<AdvertiseList/>} />
            <Route path="/advertisement/add" element={<AddAdvertise/>} />
            <Route path="/chromeUpdateInfo" element={<ChromeUpdateInfoList/>} />
            <Route path="/chromeUpdateInfo/add" element={<AddChromeUpdateInfo/>} />
          </Route>
        </Routes>
      </Router>
      </PrimeReactProvider>
  );
};

export default App;
