// AddSoftware.js
import React, { useRef, useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
// import { verifyToken } from '../../api/licenseAPI';

const AddAdvertise = () => {
  const navigate = useNavigate(); 
  const [error, setError] = useState(null);
  const [newData, setNewData] = useState({
    name: '',
    link: ''
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/');
    }
   
  }, [navigate])
  
  const toastUpload = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize = files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize = file.size || 0;
        });

        setTotalSize(_totalSize);
        toastUpload.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    // const onTemplateRemove = (file, callback) => {
    //     setTotalSize(totalSize - file.size);
    //     callback();
    // };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" >
                    <img alt={file.name} role="presentation" src={file.objectURL}  />
                </div>
                <div className="flex align-items-center">
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                {/* <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} /> */}
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', label: 'Simpan', iconOnly: false, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  const handleFieldChange = (fieldName, value) => {
    setNewData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave = async(event) => {
    //onSave(newData);
    const files = event.files;
    if (files && files.length > 0) {
      try {
        const formData = new FormData();

        const dataPost = {
          name: newData.name,
          link: newData.link,
        }
        formData.append('data', JSON.stringify(dataPost));

        // Adding files
        files.forEach((file) => {
          formData.append('files', file);
        });
        
        try {
          
        } catch (error) {
          
        }

        await axios.post(process.env.REACT_APP_API_URL + '/advertise/create/' + newData.name, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(
          response => {
            if (response.data &&response.data.success) {
              console.log("berhasil")
              toast.success('Data berhasil disimpan!', {
                position: 'top-right',
                autoClose: 3000, // Durasi tampilan toast (ms)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              navigate('/advertisement');
            }
          }
        ).catch(
          response => {
            console.log(response);
            setError('Tambah Data gagal. euy');
          }
        );
      } catch (error) {
        setError('Tambah Data gagal.');
      }
    }
    
  };
  
  const handleCancel = () => {
    navigate('/advertisement')
  };

  return (
    <div class="container is-max-desktop mt-5">
    <div className="box mt-5">
      <h2 className="title is-4">Tambah Data Baru</h2>
      <div className="field">
        <label className="label" htmlFor="name">Nama:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="name"
            value={newData.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="link">Link:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="link"
            value={newData.link}
            onChange={(e) => handleFieldChange('link', e.target.value)}
          />
        </div>
      </div>
      <div className="is-grouped control">
          <Toast ref={toastUpload}></Toast>

          <Tooltip target=".custom-choose-btn" content="Pilih" position="bottom" />
          <Tooltip target=".custom-upload-btn" content="Simpan" position="bottom" />
          <Tooltip target=".custom-cancel-btn" content="Bersihkan" position="bottom" />

          <FileUpload ref={fileUploadRef} name="demo[]" accept="image/jpeg, image/png" maxFileSize={1000000}
              onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
              headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} customUpload uploadHandler={handleSave}
              chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
      </div>
      {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
              
      <div className="field is-grouped mt-5">
        
      </div>
      <div className="field is-grouped">
        {/* <div className="control">
          <button className="button is-primary" onClick={handleSave}>Simpan</button>
        </div> */}
        <div className="control">
          <button className="button is-link" onClick={handleCancel}>Batal</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AddAdvertise;
