// AddSoftware.js
import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import { InputNumber } from 'primereact/inputnumber';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { verifyToken } from '../../api/licenseAPI';

const AddSoftware = () => {
  const navigate = useNavigate(); 
  const [error, setError] = useState(null);
  const [newData, setNewData] = useState({
    softwareId: null,
    name: ''
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/');
    }
   
  }, [navigate])
  
  const handleFieldChange = (fieldName, value) => {
    setNewData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave = async() => {
    //onSave(newData);

    try {
      const dataPost = {
        softwareId: newData.softwareId,
        name: newData.name,
      }

      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/software/create',
        data: dataPost,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success('Data berhasil disimpan!', {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate('/software');
      } else {
        setError('Tambah Data gagal.');
      }  
    } catch (error) {
      setError('Tambah Data gagal.');
    }
  };
  
  const handleCancel = () => {
    navigate('/software')
  };

  return (
    <div class="container is-max-desktop mt-5">
    <div className="box mt-5">
      <h2 className="title is-4">Tambah Data Baru</h2>
      <div className="field">
        <label className="label" htmlFor="softwareId">Software ID:</label>
        <div className="control">
          <InputNumber 
            type="text"
            id="softwareId"
            value={newData.softwareId}
            onValueChange={(e) => handleFieldChange('softwareId', e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="name">Nama:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="name"
            value={newData.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
          />
        </div>
      </div>
      {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
              
      <div className="field is-grouped mt-5">
        
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary" onClick={handleSave}>Simpan</button>
        </div>
        <div className="control">
          <button className="button is-link" onClick={handleCancel}>Batal</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AddSoftware;
