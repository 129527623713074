import { useLocation, Navigate, Outlet } from "react-router-dom";
// import useAuth from "../hooks/useAuth";
import { verifyToken } from '../api/licenseAPI';

const RequireAuth = () => {
    // const { auth } = useAuth();
    const location = useLocation();
    const isLogin = async() => {
        const user = JSON.parse(localStorage.getItem("user"))
        if (user)
        {
            verifyToken(user)
            .then(
            response => {
                if(response){
                if(response.data.success){
                    console.log("ini dari login:" + response.data.success);
                    return true;
                }
                else
                {
                    return false;
                }
                }
            }
            )
            .catch(
            response => {
                return false;
            }
            );
        }
        else
        {
            return false;
        }
        
    }
    return (
        isLogin()
            ?  <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;