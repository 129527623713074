// EditForm.js
import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import { SelectButton } from 'primereact/selectbutton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox } from "primereact/checkbox";
// import { InputNumber } from 'primereact/inputnumber';
// import { verifyToken } from '../api/licenseAPI';
import { MultiSelect } from 'primereact/multiselect';

const EditForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);

  const [editedData, setEditedData] = useState({});
  const id = urlParams.get('id');
  const options = ['Aktif', 'Tidak Aktif'];
  const packOptions = ['Trial', 'Pro'];

  const [selectedPackages, setSelectedPackages] = useState(null);
  const [packages, setPackages] = useState(null);
  const [softwareDesc, setSoftwareDesc] = useState('');

  useEffect(() => {
    getLicense(id);
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/');
    }
   
  }, [navigate, id])

  useEffect(() => {

    try {
      if (editedData.softwareId) {
        getSoftware(editedData.softwareId);
        getPackage(editedData.softwareId, id);
      }
    } catch (error) {
    }
  }, [editedData.softwareId, id])

 const getLicense = async (licenseId) => {
  const response = await axios({
    method: 'get',
    url: process.env.REACT_APP_API_URL + '/lisence/edit/' + licenseId,
  });

  const resp = response.data.license;
 
  const resp2 = resp.packageList.map((res) => {
    return {
      name : res.name,
      packageId : res.packageId,
      licenseId,
    }
  });
  setSelectedPackages(resp2)
  const exiredDate = new Date(resp.expired);
  const dataResp = {
    id : resp.id,
    softwareId : resp.softwareId,
    username : resp.username,
    email : resp.email,
    password : null,
    mac_address : resp.mac_address,
    expired : exiredDate,
    status : resp.status ? 'Aktif' : 'Tidak Aktif',
    isTrial : resp.isTrial ? 'Trial' : 'Pro'
  }
  
  setEditedData(dataResp)

 
}

const getSoftware = async(softwareId) => {
  axios({
    method: 'get',
    url: process.env.REACT_APP_API_URL + '/software/' + softwareId,
  }).then(
    response => {
    if (response.data.success) {
      setSoftwareDesc(response.data.software.softwareId + ' - ' + response.data.software.name);
    }
  });
}

 const getPackage = async (softwareId, licenseId) => {
  axios({
    method: 'get',
    url: process.env.REACT_APP_API_URL + '/module/' + softwareId,
  })
  .then(
    response => {
      if (response.data.success) {
        const dataResp = response.data.packageList.map((resp) => {
          return {
            name : resp.name,
            packageId : resp.id,
            licenseId,
          }
        });
        
        setPackages(dataResp)
      }
    }
  );
  }

  const handleFieldChange = (fieldName, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave = async() => {
    // onSave(editedData);
    try {
      const dataPost = {
        password: editedData.password,
        expired: editedData.expired,
        status: editedData.status === 'Aktif' ? true : false,
        isTrial: editedData.isTrial === 'Trial' ? true : false,
        packageList: selectedPackages
      }

      console.log(dataPost)
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/lisence/edit/' + editedData.id,
        data: dataPost,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil");
        navigate('/');
        toast.success('Data berhasil disimpan!', {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });      
      } else {
        setError('Ubah Data gagal.');
      }  
    } catch (error) {
      setError('Ubah Data gagal.');
    }
  };

  const handleCancel = () => {
    navigate('/')
  };

  const [checked, setChecked] = useState(false);
  return (
    <div class="container is-max-desktop mt-5">
    <div className="box mt-5">
      <h2 className="title is-4">Edit Data</h2>
      <div className="field">
        <label className="label" htmlFor="softwareId">Software ID:</label>
        <div className="control">
          {/* <InputNumber 
            type="text"
            id="softwareId"
            value={editedData.softwareId}
            disabled="true"
            onValueChange={(e) => handleFieldChange('softwareId', e.target.value)}
          /> */}
          <input
            className="input"
            type="text"
            id="softwareId"
            value={softwareDesc}
            disabled="true"
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="username">Username:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="username"
            value={editedData.username}
            disabled="true"
            onChange={(e) => handleFieldChange('username', e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="email">Email:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="email"
            value={editedData.email}
            disabled="true"
            onChange={(e) => handleFieldChange('email', e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="password">Password:</label>
        <div className="control">
          <div class="field is-grouped">
            <div class="control">
              <input
                className="input"
                type="password"
                id="password"
                disabled = {!checked}
                value={editedData.password}
                onChange={(e) => handleFieldChange('password',
                e.target.value)}
              />
            </div>
            <div className="control">
              <Checkbox inputId="password" onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
              <label htmlFor="password" className="ml-2">Ganti Password</label>
            </div>
          </div>
          
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="expired">Expired:</label>
        <div className="control">
        <Calendar 
           id="expired"
           value={editedData.expired} 
           onChange={(e) => handleFieldChange('expired', e.value)} 
           showIcon 
           showButtonBar 
           />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="isTrial">Modul:</label>
        <div className="control">
        <MultiSelect value={selectedPackages} onChange={(e) => setSelectedPackages(e.value)} options={packages} optionLabel="name" 
               filter  placeholder="Pilih Modul" maxSelectedLabels={5} className="input" />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="isTrial">Paket:</label>
        <div className="control">
        <SelectButton 
          id="isTrial" 
          value={editedData.isTrial} 
          onChange={(e) => handleFieldChange('isTrial', e.value)} 
          options={packOptions} 
        />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="status">Status:</label>
        <div className="control">
        <SelectButton id="status" 
        value={editedData.status} 
        onChange={(e) => handleFieldChange('status', e.value)} 
        options={options} />

        </div>
      </div>
      
      {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
      <div className="field is-grouped mt-5">
        
      </div>
      <div className="field is-grouped mt-5">
        <div className="control">
          <button className="button is-primary" onClick={handleSave}>Simpan</button>
        </div>
        <div className="control">
          <button className="button is-link" onClick={handleCancel}>Batal</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default EditForm;
