// DataTable.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
// import { ConfirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeicons/primeicons.css';
// import { verifyToken } from '../../api/licenseAPI';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';

const SoftwareList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const [deleted, setDeleted] = useState(null);
  const [visible, setVisible] = useState(false);
  // const toast = useRef(null);
  const buttonEl = useRef(null);

  const [filters, setFilters] = useState({
    softwareId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  useEffect(() => {
    RefreshData()
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/');
    }
   
  }, [navigate])

  const RefreshData = () => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/software',
    })
    .then(
      response => {
        const resp = response.data.softwareList.map(e => ({
          softwareId : e.softwareId,
          name : e.name,
          version : e.version
        }))
        setData(resp)
      }
    );
  }
  

  const handleDelete = async(deletedData) => {
    try {

      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/software/delete/' + deletedData.softwareId,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success(`Berhasil menghapus data '${deletedData.name}'`, {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        RefreshData();
      } else {
        setError('Hapus Data gagal.');
      }  
    } catch (error) {
      setError('Hapus Data gagal.');
    }
  };

  const handleAdd = () => {
    navigate('/software/add')
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    console.log(value);
    _filters['name'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
};

  const renderHeader = () => {
    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
        </div>
    );
};

const handleBackDashboard = () => {
  navigate('/');
};

// const handleRefresh = () => {
//   navigate('/');
// };

const reject = () => {
  //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
};

const handleGetSoftware = async(id) => {
  navigate('/software/update?id=' + id);
};

const actionBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
          {/* <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleEdit(rowData.id) } /> */}

          <Button onClick={() => handleGetSoftware(rowData.softwareId)} className="mr-2" icon="pi pi-cloud-upload" severity="success"/>
         
            {/* <Toast ref={toast} /> */}
            <Button ref={buttonEl} onClick={() => {
              setDeleted(rowData)
              setVisible(true)
              }} icon="pi pi-trash" />
      </React.Fragment>
  );
};



  return (
    <div className="container mt-5">
       <h1 className="title">Daftar Software</h1>
       {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
      <div className="columns is-centered">
        <div className="column">
          <div className="field is-grouped">
            <div className="control">
              <Button icon="pi pi-plus" className="button is-primary mb-3"
                onClick={() => handleAdd()} label='Tambah Data'></Button>
            </div>
            <div className="control">
              <Button icon="pi pi-caret-left" className="button has-background-danger has-text-white mb-3"
                onClick={() => handleBackDashboard()} label='Halaman Utama'></Button>
             </div>
          </div>
        </div>
      </div>
      <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={"Apakah anda yakin akan menghapus '" + (deleted ? deleted.name : "") +"' ? " }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => handleDelete(deleted)} reject={reject} />
      <div className="columns is-centered">
        <div className="column">
          <div className="table-container" style={{ overflowX: 'auto' }}>
            <div className="card">
              <DataTable value={data} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} 
              tableStyle={{ minWidth: '50rem' }}
              filters={filters} filterDisplay="row"
              header={renderHeader()}
              globalFilterFields={['softwareId', 'name']}
              >
                  <Column field="softwareId" header="Software Id" style={{ width: '25%' }}></Column>
                  <Column field="name" header="Nama" style={{ width: '25%' }}></Column>
                  <Column field="version" header="Version" style={{ width: '25%' }}></Column>
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareList;
