// AddChromeUpdateInfo.js
import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InputMask } from 'primereact/inputmask';
// import { verifyToken } from '../../api/licenseAPI';

const AddChromeUpdateInfo = () => {
  const navigate = useNavigate(); 
  const [error, setError] = useState(null);
  const [newData, setNewData] = useState({
    version: '',
    url: '',
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/');
    }
   
  }, [navigate])
  
  const handleFieldChange = (fieldName, value) => {
    console.log(fieldName);
    console.log(value);
    setNewData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave = async() => {
    //onSave(newData);

    try {
      const dataPost = {
        version: newData.version,
        url: newData.url,
      }

      console.log(newData);
      
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/chromeUpdateInfo',
        data: dataPost,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success('Data berhasil disimpan!', {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate('/chromeUpdateInfo');
      } else {
        setError('Tambah Data gagal.');
      }  
    } catch (error) {
      setError('Tambah Data gagal.');
    }
  };
  
  const handleCancel = () => {
    navigate('/chromeUpdateInfo')
  };

  return (
    <div className="container is-max-desktop mt-5">
    <div className="box mt-5">
      <h2 className="title is-4">Tambah Data Baru</h2>
      <div className="field">
        <label className="label" htmlFor="version">Versi:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="version"
            value={newData.version}
            onChange={(e) => handleFieldChange('version', e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="url">Url:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="url"
            value={newData.url}
            onChange={(e) => handleFieldChange('url', e.target.value)}
          />
        </div>
      </div>
      
      {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
              
      <div className="field is-grouped mt-5">
        
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary" onClick={handleSave}>Simpan</button>
        </div>
        <div className="control">
          <button className="button is-link" onClick={handleCancel}>Batal</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AddChromeUpdateInfo;
