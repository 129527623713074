// UpdateVersion.js
import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'react-toastify/dist/ReactToastify.css';
// import { verifyToken } from '../../api/licenseAPI';
import { InputMask } from 'primereact/inputmask';
import { FileUpload } from 'primereact/fileupload';

const UpdateVersion = () => {
  const navigate = useNavigate(); 
  const [error, setError] = useState(null);
  const [editedData, setEditedData] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  useEffect(() => {
    getSoftware(id)
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/');
    }
   
  }, [navigate, id])
  
  const getSoftware = async (id) => {
    const response = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/software/' + id,
    });
  
    const resp = response.data.software;
    const dataResp = {
      id : resp.id,
      softwareId : resp.softwareId,
      name : resp.name,
      version : resp.version,
      desc : resp.softwareId + ' - ' + resp.name
    }
   
    setEditedData(dataResp)
   
  }

  const handleSave = async() => {
    //onSave(newData);
    navigate('/software')
  };

  const handleFieldSoftChange = async(fieldName, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  return (
    <div class="container is-max-desktop mt-5">
    <div className="box mt-5">
      <h2 className="title is-4">Update Versi</h2>
      <div className="field">
        <label className="label" htmlFor="softwareId">Software ID:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="softwareId"
            value={editedData.desc}
            disabled="true"
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="version">Version:</label>
        <div className="control">
        <InputMask 
          id="version"
          mask="99.9.9.9" 
          placeholder="01.0.0.0" 
          className="input"
          value={editedData.version}
          onChange={(e) => handleFieldSoftChange('version', e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <div className="card">
            <FileUpload name="files" 
            url={process.env.REACT_APP_API_URL + '/software/upload/' + editedData.softwareId + '/' + editedData.version} multiple  
            maxFileSize={1000000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
        </div>
      </div>
      {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
              
      <div className="field is-grouped mt-5">
        
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary" onClick={handleSave}>Oke</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default UpdateVersion;
