// DataTable.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import moment from "moment"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
// import { ConfirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
// import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeicons/primeicons.css';
// import { verifyToken } from '../api/licenseAPI';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Tag } from 'primereact/tag';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [error, setError] = useState(null);
  const [softwareOptions, setSoftwareOptions] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleResetMac, setVisibleResetMac] = useState(false);
  // const toast = useRef(null);
  const buttonEl = useRef(null);
  const [deleted, setDeleted] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);

  const getSeverity = (status) => {
    switch (status) {
        case 'Pro':
            return 'info';

        case 'Trial':
        case 'Expired':
            return 'warning';
           
        case 'Aktif':
            return 'success';

        case 'Tidak Aktif':
            return 'danger';
            
        default:
              return 'info';

    }
  };
  
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
  setFilters({
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    mac_address: { value: null, matchMode: FilterMatchMode.CONTAINS },
    softwareName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    expired: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }]
    },
  });

  setGlobalFilterValue("");
};
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  useEffect(() => {
    initFilters();
    RefreshData()
    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/login');
    }
   
  }, [navigate])

  const getExiredDate = (data) => {
    return [...(data || [])].map((d) => {
      d.expired = new Date(d.expired);
      return d;
    });
  };

  const today = new Date();
  const status = (e) => {
    // Cek jika e.expired kurang dari hari ini
    const expiredDate = new Date(e.expired); // Pastikan e.expired berbentuk Date
    if (expiredDate < today) {
      return 'Expired';
    }
    return e.status ? 'Aktif' : 'Tidak Aktif';
  };

  const RefreshData = async () => {
    const dataResponse = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/LisenceList',
    })
    .then(
      response => {
        const resp = response.data.licenseList.map(e => ({
          id : e.id,
          softwareId : e.softwareId,
          email : e.email,
          mac_address : e.mac_address,
          // expired : moment(e.expired).format('DD-MM-YYYY'),
          expired : e.expired,
          status : status(e),
          isTrial : e.isTrial ? 'Trial' : 'Pro',
          lastLogin : moment(e.lastLogin).format('DD-MM-YYYY'),
          totalItems : e.totalItems,
          totalCustomers : e.totalCustomers,
          totalTransactions : e.totalTransactions,
          lastTransactionValue : e.lastTransactionValue?.toLocaleString()
        }))

        return resp;
        // setData(resp)
      }
    );

    
    const softwareMap = {};
    const softwareResponse = await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/software',
    })
    .then(
      response => {
        const options = response.data.softwareList.map(software => ({
          name: software.name,  // Tampilkan nama perangkat lunak
          id: software.softwareId     // ID sebagai nilai
        }));

        // Buat peta ID software ke nama
        response.data.softwareList.forEach(software => {
            softwareMap[software.softwareId] = software.name;
        });

        return options;
      }
    );
    
    // Tambahkan softwareName ke data utama
    const updatedData = dataResponse.map(item => ({
        ...item,
        softwareName: softwareMap[item.softwareId] || 'Unknown'  // Ganti softwareId dengan softwareName
    }));

    setData(getExiredDate(updatedData));

     // Atur options untuk filter dropdown
     const options = softwareResponse.map(software => ({
        label: software.name,
        value: software.name
    }));
    setSoftwareOptions(options);
  }

// // Template untuk filter dropdown software dengan validasi options
// const softwareFilterTemplate = (options) => {
//   // Pastikan opsi filter valid
//   const filterValue = options?.value ?? null;
//   const filterApply = options?.filterApplyCallback ?? (() => {});

//   return (
//       <Dropdown
//           value={filterValue}
//           options={softwareOptions}
//           onChange={(e) => filterApply(e.value)}
//           placeholder="Select a Software"
//           className="p-column-filter"
//           showClear
//       />
//   );
// };

let softwareFilter = (options) => <Dropdown style={{width: '100%'}} className="ui-column-filter"
        value={options.value} options={softwareOptions} 
        onChange={(e) => options.filterCallback(e.value, options.index)}/>

  const handleEdit = (id) => {
    navigate('/licence/edit?id=' + id)
  };

  const handleDeleteMac = async(rowData) => {
    try {
      const dataPost = {
        softwareId : rowData.softwareId,
        mac_address : rowData.mac_address,
      }

      console.log(dataPost);
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/logout',
        data: dataPost,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success('mac_address '+ rowData.email + ' berhasil di reset!', {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log("berhasil - 2")
        RefreshData()
      } else {
        setError('Tambah Data gagal.');
      }  
    } catch (error) {
      setError('Tambah Data gagal.');
    }
  };

  const handleDelete = async(deletedData) => {
    try {

      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/lisence/delete/' + deletedData.id,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success(`Berhasil menghapus data '${deletedData.email}'`, {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        RefreshData();
      } else {
        setError('Hapus Data gagal.');
      }  
    } catch (error) {
      setError('Hapus Data gagal.');
    }
  };

  const handleDeletes = async() => {
    try {

      const selectedDataDelete = selectedDatas.map((item) => ({
        id: item.id,
      }))
      
      console.log(selectedDatas);

      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/lisence/delete',
        data: selectedDatas,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success(`Berhasil menghapus semua data yang dipilih`, {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        RefreshData();
      } else {
        setError('Hapus Data gagal.');
      }  
    } catch (error) {
      setError('Hapus Data gagal.');
    }
  };
  const handleAdd = () => {
    navigate('/licence/add')
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    console.log(value);
    _filters['email'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
};

const renderHeader = () => {

  return (
    <div className="flex justify-content-between flex-wrap" style={{ width: '100%', padding: '10px' }}>
      <span className="p-input-icon-left flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
        />
      </span>
      {/* <div style={{ display: 'flex', gap: '8px' }}> */}
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2"
          onClick={clearFilter}
          style={{ marginRight: '8px' }}
        />
      {/* </div> */}
    </div>
  );
};
const clearFilter = () => {
  initFilters();
};

const handleLogout = () => {
  localStorage.removeItem("user")
  navigate('/login');
};

const handleRefresh = () => {
  // navigate('/');
  window.location.reload();
};

const handlePackage = () => {
  navigate('/module');
};

const handleSoftware = () => {
  navigate('/software');
};

const handleChromeUpdateInfo = () => {
  navigate('/chromeUpdateInfo');
};
// const handleAdvert = () => {
//   navigate('/advertisement');
// };
const reject = () => {
  // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
};

const packageBodyTemplate = (rowData) => {
  return <Tag value={rowData.isTrial} severity={getSeverity(rowData.isTrial)} />;
};

const statusBodyTemplate = (rowData) => {
  return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
};


const actionBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
          <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleEdit(rowData.id) } />
          <Button ref={buttonEl} onClick={() => {
              if (rowData.mac_address) {
                setDeleted(rowData)
                setVisibleResetMac(true)
              }
            } 
          } icon="pi pi-replay" className="mr-2"/>
            {/* <Toast ref={toast} /> */}
            <Button ref={buttonEl} onClick={() => {
              setDeleted(rowData)
              setVisible(true)
            } 
          } icon="pi pi-trash" className="mr-2" severity="danger"/>

      </React.Fragment>
  );
};

const leftToolbarTemplate = () => {
  return (
      <div className="flex justify-content-between flex-wrap">
          <Button className="p-input-icon-left flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2"
           label="New" icon="pi pi-plus" severity="primary" onClick={handleAdd} />
          <Button className="p-input-icon-left flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2"
           label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedDatas || !selectedDatas.length} />
          <Button
            type="button"
            icon="pi pi-file-excel"
            label="Download Excel"
            className="p-button-success flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2"
            onClick={exportExcel}
          />
      </div>
  );
};

const rightToolbarTemplate = () => {
  return  <div className="flex justify-content-between flex-wrap" style={{ width: '100%', padding: '10px' }}>
  <span className="p-input-icon-left flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2">
    <i className="pi pi-search" />
    <InputText
      value={globalFilterValue}
      onChange={onGlobalFilterChange}
      placeholder="Keyword Search"
    />
  </span>
  {/* <div style={{ display: 'flex', gap: '8px' }}> */}
    <Button
      type="button"
      icon="pi pi-filter-slash"
      label="Clear"
      className="p-button-outlined flex align-items-center justify-content-center w-4rem h-4rem bg-primary font-bold border-round m-2"
      onClick={clearFilter}
      style={{ marginRight: '8px' }}
    />
  {/* </div> */}
</div>;
};

const dateFilterTemplate = (options) => {
  return (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );
};

const formatDate = (value) => {
  // Menggunakan moment untuk memformat tanggal
  return value ? moment(value).format("DD-MM-YYYY") : ""; // Kembalikan string kosong jika value tidak ada
};

const dateBodyTemplate = (rowData) => {
  return formatDate(rowData.expired);
};

const exportExcel = () => {
  import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet((filteredData || data).map((item) => ({
        email: item.email,
        expired: moment(item.expired, 'DD-MM-YYYY').format('DD/MM/YYYY'),
        softwareName: item.softwareName,
      })));
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array'
      });
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';

      saveAsExcelFile(excelBuffer, 'data user onetobot'+ '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
};

const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((module) => {
      if (module && module.default) {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });

          module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
  });
};

const hideDeleteProductsDialog = () => {
  setDeleteProductsDialog(false);
};

const confirmDeleteSelected = () => {
  setDeleteProductsDialog(true);
};

const deleteSelectedProducts = async () => {
  let _products = data.filter((val) => !selectedDatas.includes(val));

  setDatas(_products);

  await handleDeletes();
  setDeleteProductsDialog(false);
  setSelectedDatas(null);
};

const deleteProductsDialogFooter = (
  <React.Fragment>
      <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
      <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
  </React.Fragment>
);
    return (
    <div className="container mt-5">
       <h1 className="title">Lisensi User</h1>
       {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
      <div className="columns is-centered">
        <div className="column">
          <div className="field is-grouped">
            {/* <div className="control">
              <Button icon="pi pi-plus" className="button is-primary mb-3"
                onClick={() => handleAdd()} label='Tambah Data'></Button>
            </div> */}
            <div className="control">
              <Button icon="pi pi-refresh" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                onClick={() => handleRefresh()} label='Refresh'></Button>
             </div>
             <div className="control">
              <Button icon="pi pi-box" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                onClick={() => handleSoftware()} label='Software'></Button>
             </div>
             <div className="control">
              <Button icon="pi pi-th-large" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                onClick={() => handlePackage()} label='Paket Modul'></Button>
             </div>
             <div className="control">
              <Button icon="pi pi-google" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                onClick={() => handleChromeUpdateInfo()} label='Info Versi Chrome'></Button>
             </div>
             {/* <div className="control">
              <Button icon="pi pi-ticket" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--indigo-700)'}}
                onClick={() => handleAdvert()} label='Iklan Produk'></Button>
             </div> */}
            <div className="control">
              <Button icon="pi pi-sign-out" className="button has-background-danger has-text-white mb-3"
                onClick={() => handleLogout()} label='keluar'></Button>
             </div>
          </div>
        </div>
      </div>
      <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={"Apakah anda yakin akan menghapus '" + (deleted ? deleted.email : "") +"' ? " }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => handleDelete(deleted)} reject={reject} />

      <ConfirmDialog visible={visibleResetMac} onHide={() => setVisibleResetMac(false)} message={"Apakah anda yakin akan mereset mac_address '" + (deleted ? deleted.email : "") +"' ? " }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => handleDeleteMac(deleted)} reject={reject} />
      <div className="columns is-centered">
        <div className="column">
          <div className="table-container" style={{ overflowX: 'auto' }}>
            <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
              <DataTable value={data} paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]} 
              tableStyle={{ minWidth: '50rem' }}
              filters={filters} 
              filterDisplay="menu"
              globalFilterFields={['email', 'mac_address', 'status', 'isTrial']}
              onValueChange = {(data) => setFilteredData(data)}
              selectionMode="multiple"
              selection={selectedDatas} 
              onSelectionChange={(e) => setSelectedDatas(e.value)}
              >
                <Column className="p-1" selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                  <Column className="p-1" field="id" header="Id" sortable style={{ width: '5%' }}></Column>
                  <Column className="p-1" field="email" header="Email" sortable style={{ width: '10%' }}></Column>
                  <Column className="p-1" field="mac_address" header="Mac Address" sortable style={{ width: '20%' }}></Column> 
                  {/* Filter rentang tanggal untuk kolom Expired */}
                  <Column 
                      className="p-1"
                      field="expired" 
                      header="Expired" 
                      dataType="date"
                      sortable 
                      filter 
                      // filterFunction={singleDateFilter} 
                      filterElement={dateFilterTemplate} 
                      body={dateBodyTemplate}
                      style={{ width: '15%' }}
                  ></Column>
                  {/* Gunakan softwareName yang telah dipetakan dan tambahkan properti filter */}
                  <Column className="p-1" field="softwareName" header="Software Name" sortable filter 
                        filterElement={softwareFilter} style={{ width: '15%' }}></Column>
                  <Column className="p-1" field="status" header="Status" sortable style={{ width: '8%' }} body={statusBodyTemplate}></Column>
                  <Column className="p-1" field="isTrial" header="Paket" sortable style={{ width: '7%' }} body={packageBodyTemplate}></Column>
                  <Column className="p-1" field="lastLogin" header="Last Login" sortable style={{ width: '20%' }}></Column>
                  {/* <Column field="totalItems" header="Total Items" sortable style={{ width: '10%' }}></Column>
                  <Column field="totalCustomers" header="Total Customers" sortable style={{ width: '10%' }}></Column>
                  <Column field="totalTransactions" header="Total Trans" sortable style={{ width: '10%' }}></Column>
                  <Column field="lastTransactionValue" header="Last Trans Value" sortable style={{ width: '10%' }}></Column> */}
                  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>

            <ConfirmDialog visible={deleteProductsDialog} onHide={() => setDeleteProductsDialog(false)} message={"Apakah anda yakin akan menghapus semua data yang dipilih?" }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => deleteSelectedProducts()} reject={reject} />
    </div>
  );
};

export default Dashboard;
