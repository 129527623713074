import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import axios from 'axios';
import { verifyToken } from '../api/licenseAPI';

const Login = () => {
  
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user)
    {
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(response.data.success){
      //       console.log("ini dari login:" + response.data.success);
      //       navigate('/');
      //     }
      //   }
      // );
    }
  }, [navigate])
  

  const handleLogin = async() => {

    try {
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/loginadmin',
        data: {
          email: email,
          password: password
          },
        headers: {
            'Content-Type': 'application/json',
          },
      });

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        localStorage.setItem("user", JSON.stringify({email, token: response.data.token}))
        navigate('/');
      } else {
        setError('Login failed. Please check your email and password.');
      }  
    } catch (error) {
      setError('Login failed. Please check your email and password.');
    }


  };

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-one-third">
            <div className="box">
              <h2 className="title is-4">Login</h2>
              <div className="field">
                <label className="label">email</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
              <div className="field">
                <div className="control">
                  <button className="button is-primary" onClick={handleLogin}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
