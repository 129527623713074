// AddForm.js
import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import { SelectButton } from 'primereact/selectbutton';
import { InputNumber } from 'primereact/inputnumber';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { verifyToken } from '../api/licenseAPI';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

const AddForm = () => {
  const navigate = useNavigate(); 
  const [error, setError] = useState(null);
  const options = ['Aktif', 'Tidak Aktif'];
  const packOptions = ['Trial', 'Pro'];
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [newData, setNewData] = useState({
    softwareId: 0,
    username: '',
    email: '',
    password: '',
    expired: null,
    status: 'Aktif',
    isTrial: 'Trial',
  });

  const [newDataSoft, setNewDataSoft] = useState({
    softwareId: 0,
    name: '',
  });

  const [selectedPackages, setSelectedPackages] = useState(null);
  const [packages, setPackages] = useState(null);

  const [selectedSoftware, setSelectedSoftware] = useState(null);
  const [softwares, setSoftwares] = useState(null);

  useEffect(() => {
    getSoftware();
    if (newData.softwareId !== 0) {
      getPackage(newData.softwareId);
      getPackageDefault(newData.softwareId);
    } else {
      setPackages(null);
      setSelectedPackages(null);
    }

    const user = JSON.parse(localStorage.getItem("user"))
    if (user){
      // verifyToken(user)
      // .then(
      //   response => {
      //     if(!response.data.success){
      //       console.log("ini dari dash:" + response.data.success);
      //       localStorage.removeItem("user")
      //       navigate('/');
      //     }
      //   }
      // );
    }
    else
    {
      navigate('/');
    }
   
  }, [navigate, newData.softwareId])

  const handleFieldChange = async(fieldName, value) => {
    setNewData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleFieldSoftChange = async(fieldName, value) => {
    setNewDataSoft((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  const getPackage = async(id) => {
    await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/module/' + id,
    })
    .then(
      response => {
        const dataResp = response.data.packageList.map((resp) => {
          return {
            name : resp.name,
            packageId : resp.id
          }
        });
        
        setPackages(dataResp)
      }
    );
  }

  const getSoftware = async() => {
    await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/software',
    })
    .then(
      response => {
        const dataResp = response.data.softwareList.map((resp) => {
          return {
            name : resp.name,
            softwareId : resp.softwareId
          }
        });
        
        setSoftwares(dataResp)
      }
    );
  }

  const getPackageDefault = async(id) => {
    await axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/module/default/' + id,
    })
    .then(
      response => {
        const dataResp = response.data.packageList.map((resp) => {
          return {
            name : resp.name,
            packageId : resp.id
          }
        });
        
        setSelectedPackages(dataResp)
      }
    );
  }

  const handleSave = async() => {
    //onSave(newData);

    // console.log(selectedPackages);
    try {
      const dataPost = {
        softwareId: newData.softwareId,
        username: newData.username,
        email: newData.email,
        password:  newData.password,
        expired: newData.expired,
        status: newData.status === 'Aktif' ? true : false,
        isTrial: newData.isTrial === 'Trial' ? true : false,
        packageList: selectedPackages
      }

      console.log(dataPost);
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/register',
        data: dataPost,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        console.log("berhasil")
        toast.success('Data berhasil disimpan!', {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log("berhasil - 2")
        navigate('/');
      } else {
        setError('Tambah Data gagal.');
      }  
    } catch (error) {
      setError('Tambah Data gagal.');
    }
  };
  
  const handleSaveSoft = async() => {
    //onSave(newData);

    // console.log(selectedPackages);
    try {
      console.log(newDataSoft);
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/software/create',
        data: newDataSoft,
        headers: {
            'Content-Type': 'application/json',
          },
      })

      // const response = dispatch(login(email, password));
      if (response.data &&response.data.success) {
        toast.success('Data berhasil disimpan!', {
          position: 'top-right',
          autoClose: 3000, // Durasi tampilan toast (ms)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setVisibleDialog(false);
        getSoftware();
        // navigate('/');
      } else {
        setError('Tambah Data gagal.');
      }  
    } catch (error) {
      setError('Tambah Data gagal.');
    }
  };

  const handleCancel = () => {
    navigate('/')
  };

  const selectedSoftwareTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.softwareId + ' - ' +  option.name}</div>
            </div>
          );
      }
      return <span>{props.placeholder}</span>;
  };

  const softwareOptionTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{option.softwareId + ' - ' +  option.name}</div>
        </div>
      );
  };


    const footerContent = (
        <div>
            <Button label="Batal" icon="pi pi-times" onClick={() => setVisibleDialog(false)} className="p-button-text" />
            <Button label="Simpan" icon="pi pi-check" onClick={() => handleSaveSoft()} autoFocus />
        </div>
    );

  return (
    <div class="container is-max-desktop mt-5">
    <div className="box mt-5">
      <h2 className="title is-4">Tambah Data Baru</h2>
      <div className="field">
          <span className="p-input-icon-left">
            <label className="label" htmlFor="softwareId">Software ID:</label>
            <div className="flex flex-wrap justify-content-center gap-3">
              {/* <InputNumber 
                type="text"
                id="softwareId"
                value={newData.softwareId}
                onValueChange={(e) => handleFieldChange('softwareId', e.target.value)}
              /> */}
              <Dropdown value={selectedSoftware} 
                onChange={(e) => {
                  setSelectedSoftware(e.value)
                  handleFieldChange('softwareId', e.value.softwareId)
                }} 
                options={softwares} optionLabel="name" placeholder="Pilih Software" 
                filter valueTemplate={selectedSoftwareTemplate} itemTemplate={softwareOptionTemplate} className="w-full md:w-14rem" />
              <Button label="Tambah" icon="pi pi-plus" onClick={() => setVisibleDialog(true)} />
              <Dialog header="Tambah Software Baru" visible={visibleDialog} style={{ width: '50vw' }} onHide={() => setVisibleDialog(false)} footer={footerContent}>
              <div class="container is-max-desktop mt-5">
                <div className="mt-5">
                  <div className="field">
                    <label className="label" htmlFor="softwareId">Id:</label>
                    <div className="control">
                      <InputNumber 
                        type="text"
                        id="softwareId"
                        value={newDataSoft.softwareId}
                        onValueChange={(e) => handleFieldSoftChange('softwareId', e.target.value)}
                      /> 
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="softwareName">Nama:</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        id="softwareName"
                        value={newDataSoft.name}
                        onChange={(e) => handleFieldSoftChange('name', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              </Dialog>
            </div>
          </span>
      </div>
      <div className="field">
        <label className="label" htmlFor="username">Username:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="username"
            value={newData.username}
            onChange={(e) => handleFieldChange('username', e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="email">Email:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            id="email"
            value={newData.email}
            onChange={(e) => handleFieldChange('email', e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="password">Password:</label>
        <div className="control">
          <input
            className="input"
            type="password"
            id="password"
            value={newData.password}
            onChange={(e) => handleFieldChange('password',
            e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="expired">Expired:</label>
        <div className="control">
          <Calendar 
           id="expired"
           value={newData.expired} 
           onChange={(e) => handleFieldChange('expired', e.value)} 
           showIcon 
           showButtonBar 
           />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="isTrial">Modul:</label>
        <div className="control">
        <MultiSelect value={selectedPackages} onChange={(e) => setSelectedPackages(e.value)} options={packages} optionLabel="name" 
               filter  placeholder="Pilih Modul" maxSelectedLabels={5} className="input" />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="isTrial">Paket:</label>
        <div className="control">
        <SelectButton 
          id="isTrial" 
          value={newData.isTrial} 
          onChange={(e) => handleFieldChange('isTrial', e.value)} 
          options={packOptions} 
        />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="status">Status:</label>
        <div className="control">
        <SelectButton 
          id="status" 
          value={newData.status} 
          onChange={(e) => handleFieldChange('status', e.value)} 
          options={options} 
        />
        </div>
      </div>
      {error && (
                <div className="notification is-danger">
                  <button className="delete" onClick={() => setError(null)}></button>
                  {error}
                </div>
              )}
              
      <div className="field is-grouped mt-5">
        
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary" onClick={handleSave}>Simpan</button>
        </div>
        <div className="control">
          <button className="button is-link" onClick={handleCancel}>Batal</button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AddForm;
